import React from "react";
import Slider from "react-slick";


const CarrouselVendu = ({ vehicles }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div>
      <h2> Véhicules vendus </h2>
      {vehicles && (
        <Slider {...settings}>
          {vehicles.map((vehicle) => (
            <div key={vehicle.id}>
              <img src={vehicle.images[0]} alt={vehicle.name} />
              <h3>{vehicle.name}</h3>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default CarrouselVendu;